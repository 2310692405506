import React from 'react';
import { Link, useLocation } from "react-router-dom";

function Item({ destino, nome }){

    const location = useLocation();

    const isCurrentURL = (url) => {
        return location.pathname.toLowerCase() === url.toLowerCase();
    }

    return (
        <Link
            to={`${destino}`}
            className={`text-jet-50 uppercase flex-shrink mx-1`}
        >
            <h2 className={`mx-2 my-1 text-sm font-jetbrains opacity-25 ${(isCurrentURL(destino) ? 'font-bold opacity-100' : '')}`}>{nome}</h2>
            <div className={`h-0.5 lg:h-1 bg-jet-50 w-full transition transform opacity-25 ${(isCurrentURL(destino) ? 'scale-x-110 opacity-100 bg-azul' : '')}`}/>
        </Link>
    )
}

export default Item;