import React from 'react';
import Item from "./Item";

function Contatos (){
    return (
        <div className={'grid center justify-center grid-cols-5 mt-2 mb-4'}>
            <Item nome="GitHub" destino="/sobre" icon={['fab', 'github']} />
            <Item nome="LinkedIn" destino="/portfolio" icon={['fab', 'linkedin']} />
            <Item nome="Email" destino="/cursos" icon={['fas', 'envelope-open-text']} />
            <Item nome="Instagram" destino="/habilidades" icon={['fab', 'instagram']} />
            <Item nome="Facebook" destino="/contatos" icon={['fab', 'facebook']} />
        </div>
    )
}

export default Contatos;