import React from 'react';

function Item({ item : { nome, link, instituicao, ano }, indice }){
    return (
        <div className={'group'}>
            <div className={'flex mb-2'}>
                { indice > 0 && <div className={'py-1 w-1 bg-white opacity-25 ml-4'} /> }
                <span className={'text-jet-50 text-xs ml-4 transition transform group-hover:translate-x-1'}>{ano} - {instituicao}</span>
            </div>
            <a className={'px-2 py-1 bg-jet-50 rounded-sm block transition transform group-hover:translate-x-3'} href={link} target="_blank">
                <span className={`transform transition font-jetbrains text-sm cursor-pointer`}>{nome}</span>
            </a>
        </div>
    );
}

export default Item;