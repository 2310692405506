import React from 'react';

function Item({ item : { nome, link, imagem = "" } }){
    return (
        <a className={`px-2 py-1 text-center rounded-sm transform transition hover:scale-105 hover:shadow font-jetbrains text-sm group cursor-pointer overflow-hidden bg-white`} href={link} target="_blank">
            <img src={imagem} className={'max-w-10 max-h-10 my-2 w-full h-full object-contain'} />
            {nome}
        </a>
    );
}

export default Item;