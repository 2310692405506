import React from 'react';
import Item from "./Item";

function Menu (){
    return (
        <div className={'container flex center gap-4 justify-center flex-wrap mt-2'}>
            <Item nome="Início" destino="/" />
            <Item nome="Portfolio" destino="/portfolio" />
            <Item nome="Cursos" destino="/cursos" />
            <Item nome="Habilidades" destino="/habilidades" />
        </div>
    )
}

export default Menu;