import React from 'react';
import Item from "../../components/Cursos/Item";

function Cursos(){

    const itens = [
        { nome: "Spring Boot API REST - Segurança da API, cache e monitoramento",               link: "https://cursos.alura.com.br/user/otaviolms/course/spring-boot-seguranca-cache-monitoramento/certificate", instituicao: "Alura", ano: "2021" },              // 08/12/2021
        { nome: "Spring Boot API REST - Construa uma API",                                      link: "https://cursos.alura.com.br/user/otaviolms/course/spring-boot-api-rest/certificate", instituicao: "Alura", ano: "2021" },              // 08/12/2021
        { nome: "API REST com Kotlin e Spring Boot - Camada WEB",                               link: "https://cursos.alura.com.br/user/otaviolms/course/api-rest-kotlin-spring-boot-camada-web/certificate", instituicao: "Alura", ano: "2021" },              // 07/12/2021
        { nome: "Spark AR - Fundamentos Básicos",                                               link: "https://cursos.alura.com.br/user/otaviolms/course/spark-ar-fundamentos-basicos/certificate", instituicao: "Alura", ano: "2021" },              // 23/11/2021
        { nome: "Raspberry Pi Node-Red - Elabore aplicações conectando dispositivos e serviços",link: "https://cursos.alura.com.br/user/otaviolms/course/raspberry-pi-node-red/certificate", instituicao: "Alura", ano: "2021" },              // 04/11/2021
        { nome: "Python 3 Parte 1 - Introdução à nova versão da linguagem",                     link: "https://cursos.alura.com.br/user/otaviolms/course/python-3-introducao-a-nova-versao-da-linguagem/certificate", instituicao: "Alura", ano: "2021" },              // 02/11/2021
        { nome: "Raspberry Pi Servidor - Montando um servidor para desenvolvimento",            link: "https://cursos.alura.com.br/user/otaviolms/course/raspberrypi-servidor/certificate", instituicao: "Alura", ano: "2021" },              // 02/11/2021
        { nome: "Raspberry Pi Parte 1 - Da instalação ao Media Center",                         link: "https://cursos.alura.com.br/user/otaviolms/course/raspberrypi-da-instalacao-ao-media-center/certificate", instituicao: "Alura", ano: "2021" },              // 02/11/2021
        // { nome: "Ética e Compliance 2021",                                                     link: "", instituicao: "Indra", ano: "2021" },           // 15/09/2021
        { nome: "Tailwind CSS do básico ao avançado + Projetos",                                link: "https://www.udemy.com/certificate/UC-a7b24deb-85c8-4e85-afc2-4862ef2fb721/", instituicao: "Udemy", ano: "2021" },              // 08/09/2021
        // { nome: "Marco Normativo de Seguridad de la Información",                               link: "", instituicao: "FIAP", ano: "2021" },            // 06/09/2021
        { nome: "React Native com Expo - Navegação com menu e suporte às telas",                link: "https://cursos.alura.com.br/user/otaviolms/course/react-native-expo-navegacao-menu-suporte-telas/certificate", instituicao: "Alura", ano: "2021" },              // 28/08/2021
        { nome: "React Router - Navegação em uma SPA",                                          link: "https://cursos.alura.com.br/user/otaviolms/course/react-router-navegacao-spa/certificate", instituicao: "Alura", ano: "2021" },              // 24/08/2021
        { nome: "Formação React JS",                                                            link: "https://cursos.alura.com.br/user/otaviolms/degree-react-js-123128/certificate", instituicao: "Alura", ano: "2021" },              // 24/08/2021
        { nome: "React - Abstraindo seu CSS com Styled Components",                             link: "https://cursos.alura.com.br/user/otaviolms/course/react-styled-components/certificate", instituicao: "Alura", ano: "2021" },              // 24/08/2021
        { nome: "React - Automatizando os testes em aplicações front-end",                      link: "https://cursos.alura.com.br/user/otaviolms/course/react-automatizando-testes/certificate", instituicao: "Alura", ano: "2021" },              // 24/08/2021
        { nome: "React - Hooks, contextos e boas práticas",                                     link: "https://cursos.alura.com.br/user/otaviolms/course/react-hooks-e-formularios/certificate", instituicao: "Alura", ano: "2021" },              // 23/08/2021
        { nome: "React - Ciclo de vida dos componentes",                                        link: "https://cursos.alura.com.br/user/otaviolms/course/react-ciclo-de-vida/certificate", instituicao: "Alura", ano: "2021" },              // 22/08/2021
        { nome: "React - Function Components, uma abordagem moderna",                           link: "https://cursos.alura.com.br/user/otaviolms/course/react-function-components/certificate", instituicao: "Alura", ano: "2021" },              // 22/08/2021
        { nome: "Javascript - Programando a orientação a objetos",                              link: "https://cursos.alura.com.br/user/otaviolms/course/javascritpt-orientacao-objetos/certificate", instituicao: "Alura", ano: "2021" },              // 20/08/2021
        { nome: "Javascript - Interfaces e herança em orientação a objetos",                    link: "https://cursos.alura.com.br/user/otaviolms/course/javascript-polimorfismo/certificate", instituicao: "Alura", ano: "2021" },              // 20/08/2021
        { nome: "Javascript - Primeiros passos com a linguagem",                                link: "https://cursos.alura.com.br/user/otaviolms/course/javascript-introducao/certificate", instituicao: "Alura", ano: "2021" },              // 19/08/2021
        { nome: "C# Parte 4 - Entendendo Exceções",                                             link: "https://cursos.alura.com.br/user/otaviolms/course/csharp-parte-4-excecoes/certificate", instituicao: "Alura", ano: "2021" },              // 18/08/2021
        { nome: "C# Parte 3 - Entendendo herança e interface",                                  link: "https://cursos.alura.com.br/user/otaviolms/course/csharp-parte-3-heranca-interfaces-polimorfismo/certificate", instituicao: "Alura", ano: "2021" },              // 17/08/2021
        { nome: "C# Parte 2 - Introdução à orientação a objetos",                               link: "https://cursos.alura.com.br/user/otaviolms/course/csharp-parte-2-introducao-orientacao-objetos/certificate", instituicao: "Alura", ano: "2021" },              // 17/08/2021
        { nome: "C# Parte 1 - Primeiros passos",                                                link: "https://cursos.alura.com.br/user/otaviolms/course/csharp-parte-1-primeiros-passos/certificate", instituicao: "Alura", ano: "2021" },              // 17/08/2021
        { nome: "Android com Kotlin - Crie o seu primeiro App",                                 link: "https://cursos.alura.com.br/user/otaviolms/course/fundamentos-android-kotlin/certificate", instituicao: "Alura", ano: "2021" },              // 16/08/2021
        { nome: "Formação Kotlin",                                                              link: "https://cursos.alura.com.br/user/otaviolms/degree-kotlin-68329/certificate", instituicao: "Alura", ano: "2021" },              // 15/08/2021
        { nome: "Kotlin - Recursos do paradigma funcional",                                     link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-recursos-do-paradigma-funcional/certificate", instituicao: "Alura", ano: "2021" },              // 15/08/2021
        { nome: "Kotlin - Desenvolva com coleções, arrays e listas",                            link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-introducao-collections-arrays-listas/certificate", instituicao: "Alura", ano: "2021" },              // 13/08/2021
        { nome: "Kotlin - Lidando com exceptions e referências nulas",                          link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-exceptions-null-safety/certificate", instituicao: "Alura", ano: "2021" },              // 13/08/2021
        { nome: "Kotlin - Recursos da linguagem com pacotes e composição",                      link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-recursos-da-linguagem-com-pacotes-e-composicao/certificate", instituicao: "Alura", ano: "2021" },              // 13/08/2021
        { nome: "Kotlin collections - Set e Map",                                               link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-collections-set-map/certificate", instituicao: "Alura", ano: "2021" },              // 13/08/2021
        { nome: "Kotlin - Herança, polimorfismo e interface",                                   link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-heranca-polimorfismo-interface/certificate", instituicao: "Alura", ano: "2021" },              // 12/08/2021
        { nome: "Kotlin - Primeiros passos e orientação a objetos",                             link: "https://cursos.alura.com.br/user/otaviolms/course/kotlin-orientacao-objetos/certificate", instituicao: "Alura", ano: "2021" },              // 12/08/2021
        { nome: "Java JRE e JDK - Escreva o seu primeiro código com Eclipse",                   link: "https://cursos.alura.com.br/user/otaviolms/course/java-primeiros-passos/certificate", instituicao: "Alura", ano: "2021" },              // 11/08/2021
        { nome: "PNL - Comunicação efetiva para o dia a dia",                                   link: "https://cursos.alura.com.br/user/otaviolms/course/pnl-comunicacao-efetiva/certificate", instituicao: "Alura", ano: "2021" },              // 27/07/2021
        { nome: "Persistência com Flutter - Crie um App com Armazenamento Interno",             link: "https://cursos.alura.com.br/user/otaviolms/course/flutter-persistencia-interna/certificate", instituicao: "Alura", ano: "2021" },              // 22/07/2021
        { nome: "React - Entendendo como a biblioteca funciona",                                link: "https://cursos.alura.com.br/user/otaviolms/course/react-js/certificate", instituicao: "Alura", ano: "2021" },              // 20/07/2021
        { nome: "VueJS Parte 1 - Construindo Single Page Applications",                         link: "https://cursos.alura.com.br/user/otaviolms/course/vue-parte1/certificate", instituicao: "Alura", ano: "2021" },              // 18/07/2021
        { nome: "Fundamentos de Flutter - Crie o seu primeiro App",                             link: "https://cursos.alura.com.br/user/otaviolms/course/flutter-fundamentos/certificate", instituicao: "Alura", ano: "2021" },              // 27/06/2021
        { nome: "REST com NodeJS - API com Express e MySQL",                                    link: "https://cursos.alura.com.br/user/otaviolms/course/node-rest-api/certificate", instituicao: "Alura", ano: "2021" },              // 14/06/2021
        { nome: "Docker - Criando containers sem dor de cabeça",                                link: "https://cursos.alura.com.br/user/otaviolms/course/docker-e-docker-compose/certificate", instituicao: "Alura", ano: "2021" },              // 06/06/2021
        { nome: "Avançando com PHP - Arrays, strings, função e web",                            link: "https://cursos.alura.com.br/user/otaviolms/course/php-arrays-strings-funcoes/certificate", instituicao: "Alura", ano: "2021" },              // 06/06/2021
        { nome: "Introdução ao PHP - Primeiros passos com a linguagem",                         link: "https://cursos.alura.com.br/user/otaviolms/course/php-primeiros-passos/certificate", instituicao: "Alura", ano: "2021" },              // 04/06/2021
        { nome: "Fundamentos de Agilidade - Seus primeiros passos para a transformação Ágil",   link: "https://cursos.alura.com.br/user/otaviolms/course/fundamentos-agilidade/certificate", instituicao: "Alura", ano: "2021" },              // 30/05/2021
        { nome: "Git e GitHub - Controle e compartilhe seu código",                             link: "https://cursos.alura.com.br/user/otaviolms/course/git-github-controle-de-versao/certificate", instituicao: "Alura", ano: "2021" },              // 30/05/2021
        { nome: "Javascript - Conhecendo o browser e padrões de projeto",                       link: "https://cursos.alura.com.br/user/otaviolms/course/javascript-es6-orientacao-a-objetos-parte-1/certificate", instituicao: "Alura", ano: "2021" },              // 21/05/2021
        { nome: "Aprenda a criar sites animados com Greensock",                                 link: "https://www.udemy.com/certificate/UC-3f45bf98-fdf4-4fd5-ae96-d5a368744dd5/", instituicao: "Udemy", ano: "2021" },              // 15/04/2021
        { nome: "Mapping in Leaflet JS",                                                        link: "https://www.udemy.com/certificate/UC-4a53e284-d4a0-4a89-9686-7a3b5ad44023/", instituicao: "Udemy", ano: "2021" },              // 15/04/2021
        { nome: "Chart JS, the complete guide. ChartJS missing manual",                         link: "https://www.udemy.com/certificate/UC-e22e8f93-3a16-4b9f-9cf1-51dd8ba9122a/", instituicao: "Udemy", ano: "2021" },              // 15/04/2021
        { nome: "Electron From Scratch Build Desktop Apps With Javascript",                     link: "https://www.udemy.com/certificate/UC-2900b760-458c-45b2-ba97-dbe67ccace02/", instituicao: "Udemy", ano: "2021" },              // 15/04/2021
        { nome: "Manual Prático do Deep Learning - Redes Neurais Profundas",                    link: "https://www.udemy.com/certificate/UC-09396465-b476-4622-83f2-ade901eddb88/", instituicao: "Udemy", ano: "2021" },              // 15/04/2021
        { nome: "Engenharia da Computação",                                                     link: "", instituicao: "FIAP", ano: "2018" },               // 22/01/2018
        { nome: "Inteligência Artificial - Algoritmos Genéticos",                               link: "", instituicao: "Udemy", ano: "2017" },              // 30/11/2017
        { nome: "Desenvolvimento de Aplicações em Node.JS para Internet das Coisas",            link: "", instituicao: "FIAP Shift", ano: "2014" },         // 13/12/2014
        { nome: "Gerando Inovação em Internet das Coisas",                                      link: "", instituicao: "FIAP Shift", ano: "2014" },         // 29/11/2014
        { nome: "6Nuts - Desafio de Férias TIZEN",                                              link: "", instituicao: "Samsung Ocean", ano: "2014" },      // 08/08/2014
        { nome: "Introdução ao Samsung Mobile SDK",                                             link: "", instituicao: "Samsung Ocean", ano: "2014" },      // 15/07/2014
        { nome: "Introdução ao Samsung Smart TV SDK",                                           link: "", instituicao: "Samsung Ocean", ano: "2014" },      // 15/07/2014
        { nome: "Laboratório de Desenvolvimento de Aplicativos & Samsung Mobile SDK",           link: "", instituicao: "Samsung Ocean", ano: "2014" },      // 11/07/2014
        { nome: "Samsung Ocean Hackathon",                                                      link: "", instituicao: "Samsung", ano: "2014" },            // 31/05/2014
        { nome: "Android - Desenvolvimento para Dispositivos Móveis",                           link: "", instituicao: "Impacta Tecnologia", ano: "2013" }, // 25/05/2013
        { nome: "MySQL - Módulo I",                                                             link: "", instituicao: "Impacta Tecnologia", ano: "2013" }, // 23/02/2013
        { nome: "Java Programmer - Módulo I",                                                   link: "", instituicao: "Impacta Tecnologia", ano: "2012" }, // 21/12/2012
        { nome: "Dreamweaver CS5 - Módulo I",                                                   link: "", instituicao: "Impacta Tecnologia", ano: "2011" }, // 11/12/2011
        { nome: "XHTML - Tableless",                                                            link: "", instituicao: "Impacta Tecnologia", ano: "2011" }, // 16/10/2011
        { nome: "Tecnologia e Projeto Web",                                                     link: "", instituicao: "Impacta Tecnologia", ano: "2010" }, // 12/09/2010
    ]

    return (
        <section className={`container flex flex-col place-items-center mb-1 p-4 overflow-y-auto flex-1`}>
            <div className={'flex flex-col gap-2 place-items-start'}>
                {
                    itens.map((item, i) => {
                        return <Item item={item} indice={i} />
                    })
                }
            </div>
        </section>
    );
}

export default Cursos;