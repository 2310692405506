import React from 'react';

function Portfolio({ item : { nome, img, link } }){
    return (
        <a className={`p-2 rounded text-center bg-jet-50 font-jetbrains text-sm group cursor-pointer overflow-hidden`} href={link} target="_blank">
            <img src={`/${img}`} className={`w-full h-full object-cover rounded transition transform group-hover:scale-110`} />
            <span className={`bg-jet-600 px-4 py-1 bottom-8 relative transition group-hover:bg-jet-400 text-white shadow`}>{nome}</span>
        </a>
    );
}

export default Portfolio;