import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Item({ destino, nome, icon }){
    return (
        <a href={destino} className={'text-verde-neon group text-center mx-2 mb-2 lg:-mb-2 transition lg:transform translate-y-0 hover:-translate-y-1'}>
            <FontAwesomeIcon icon={icon} className={'text-xl'} />
            <h3 className={'lg:opacity-0 group-hover:opacity-100 transition lg:transform translate-y-4 group-hover:-translate-y-1'}>{nome}</h3>
        </a>
    )
}

export default Item;