import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {library} from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons';

const iconSolidList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix" )
    .map(icon => Icons[icon])
library.add(...iconSolidList)

const iconBrandsList = Object
    .keys(IconsBrands)
    .filter(key => key !== "fab" && key !== "prefix" )
    .map(icon => IconsBrands[icon])
library.add(...iconBrandsList)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
