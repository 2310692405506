import React from 'react';
import Typewriter from 'typewriter-effect';

function Inicio(){
    const habilidades = ['"Kotlin"', '"Javascript"', '"React"', '"React Native"', '"TailWindCSS"', '"MongoDB"', '"NodeJS"', '"HTML"', '"CSS"', '"Arduino"', '"Android"', '"MySQL"', '"Java"']

    return (
        <section className={`container h-full flex flex-col lg:flex-row justify-center place-items-center md:gap-6 px-6 overflow-auto`}>
            <img src={"/perfil.png"} className={'xl:h-64 xl:w-64 lg:h-48 lg:w-48 h-32 w-32 mt-16 lg:mt-0'} alt="Foto de perfil do Otávio Luiz"/>
            <code className={'xl:text-3xl lg:text-2xl md:text-xl text-lg mt-6 md:mt-0 font-jetbrains font-extrabold flex flex-col md:gap-0 lg:gap-2'}>
                <p>
                    <span className="text-laranja">val</span> <span className="text-jet-50">nome =</span> <span className="text-verde inline-block">"Otávio Luiz"</span>
                </p>
                <p>
                    <span className="text-laranja">val</span> <span className="text-jet-50">profissao =</span> <span className="text-verde">"Desenvolvedor"</span>
                </p>
                <p>
                    <span className="text-laranja">val</span> <span className="text-jet-50">habilidades =</span> <span className="text-verde inline-block">
                        <Typewriter
                            options={{
                                strings: habilidades,
                                autoStart: true,
                                delay: 50,
                                loop: true,
                            }}
                        />
                    </span>
                </p>
                <p className={'text-sm md:text-base text-verde'}>
                    /** <br/>
                     * Sou graduado em Engenharia da Computação pela <a href="https://www.fiap.com.br/" className="font-bold underline">FIAP</a> com vasta experiência<br />
                     * em desenvolvimento Android nativo com Kotlin e Java. Atualmente, trabalho na <a href="https://www.minsait.com/pt" className="font-bold underline">Minsait</a><br />
                     * onde atuo no projeto <a href="https://play.google.com/store/apps/details?id=br.com.bb.investimentosbb" className="font-bold underline">Banco do Brasil - App Investimentos</a>. Fui responsável pelo<br />
                     * desenvolvimentodo aplicativo <a href="https://play.google.com/store/apps/details?id=br.com.shiloh.shilohfiv.shilohfiv" className="font-bold underline">Shiloh FIV</a> na Clínica Shiloh para acompanhamento do<br />
                     * processo de Fertilização In Vitro, fundador da Facedoo softwares para<br />
                     * gerenciamento escolar e ministrei cursos, além do desenvolvimento de ferramentas<br />
                     * nos 10 anos de experiência no Colégio Passionista São Paulo da Cruz.<br />
                     */
                </p>
            </code>
        </section>
    );
}

export default Inicio;