import './App.css';
import Menu from './components/Menu';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Portfolio from "./pages/Portfolio";
import Cursos from "./pages/Cursos";
import Contatos from "./components/Contatos";
import Habilidades from "./pages/Habilidades";
import Inicio from "./pages/Inicio";

function App() {
  return (
    <div className="App h-screen w-screen fixed flex flex-col place-items-center bg-back-medio">
      <Router>
        <Menu />
        <Switch>
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/cursos" component={Cursos} />
          <Route path="/habilidades" component={Habilidades} />
          <Route path="/" component={Inicio} />
          {/*<Route><Pagina404 /></Route>*/}
        </Switch>
        <Contatos />
      </Router>
    </div>
  );
}

export default App;
