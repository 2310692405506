import React from 'react';
import Item from "../../components/Portfolio/Item";

function Portfolio(){

    const itens = [
        { nome: "Tello 3D", img: "tello_thumb.png", link: "https://github.com/otaviolms/Tello-3DTool" },
        { nome: "Fala Tatá", img: "fala_tata_thumb.png", link: "https://github.com/otaviolms/Pass-the-Bomb" },
        { nome: "Bingo", img: "bingo_thumb.png", link: "https://github.com/otaviolms/Sorteador-de-Bingo" },
        { nome: "P4ssw0rd Game", img: "password_thumb.png", link: "https://github.com/otaviolms/P4ssw0rd-Game" },
    ]

    return (
        <section className={`container grid gap-4 grid-cols-2 grid-rows-2 p-4 overflow-y-auto flex-1`}>
            {
                itens.map(item => {
                    return <Item item={item} />
                })
            }
        </section>
    );
}

export default Portfolio;